import React, { useEffect } from "react";
import ShiftItem from "./ShiftItem";
import SortUpIcon from "@rsuite/icons/SortUp";
import SortDownIcon from "@rsuite/icons/SortDown";
import MinusIcon from "@rsuite/icons/Minus";
import {isMobile} from 'react-device-detect';
import { Accordion } from 'rsuite';


const CustomSidebar = (params) => {
  useEffect(() => {
  }, [params.month]);

  const status = (item) => {
    switch (item) {
      case null:
        return <MinusIcon color="blueviolet" fontSize="2em" />;
      case true:
        return <SortUpIcon color="green" fontSize="2em" />;
      case false:
        return <SortDownIcon color="red" fontSize="2em" />;
      default:
        return "Nada";
    }
  };

  const getMobileScroll = () => {
    if (isMobile) {
      return {}
    }
    return { overflowY: "scroll", maxHeight: "90vh" }
  }

  const getShifts = (upcoming=false) => {
    if (params.data != null) {
      var summary = null;
      const curremtDate = new Date();
      if (upcoming) {
        summary = params.data.Summary.filter((item, key) => {
          return (new Date(item.end) >= curremtDate)
        })
      } else {
        summary = params.data.Summary.filter((item, key) => {
          return (new Date(item.end) < curremtDate)
        })
      }
      return summary.map((item, key) => {
        if (item.display) {
        return <ShiftItem shiftData={item} key={key}></ShiftItem>;
        }
      });
    } else {
      return "No Shifts";
    }
  };

  if (params.loading) {
    return (
      <div class="col-sm-4 pt-5" style={getMobileScroll()}>
        <div class="spinner-grow text-warning" role="status">
        </div>
      </div>
    );
  } else if (params.data != null) {
    return (
      <div class="col-sm-4" style={getMobileScroll()}>
        <div class="d-flex flex-column">
          <div class="p-2">
            <div class="d-flex flex-column align-items-center mb-3">
              <div className="p-2">
                <h4>
                  {params.month.toLocaleString("en-us", {
                    month: "short",
                    year: "numeric",
                  })}
                </h4>
              </div>
              <div className="p-2">
                <h5>
                  {params.data.TimeWorked}H{" "}
                  {status(params.data.StatusData.TimeHigher)}
                </h5>
              </div>
              <div className="p-2">
                <h5>
                  R{params.data.TotalEarned}{" "}
                  {status(params.data.StatusData.MonthHigher)}
                </h5>
              </div>
            </div>
          </div>
          <Accordion>
          <Accordion.Panel header="Passed Shifts">
          <div class="p-2 overflow-auto h-100">{getShifts(false)}</div>
          </Accordion.Panel>
          <Accordion.Panel header="Upcoming Shifts" defaultExpanded>
          <div class="p-2 overflow-auto h-100">{getShifts(true)}</div>
          </Accordion.Panel>
        </Accordion>
          
        </div>
      </div>
    );
  }
};

export default CustomSidebar;
