import { Calendar, Badge } from "rsuite";
import React, { useEffect } from "react";

import "rsuite/dist/rsuite.min.css";

const Cal = ({ shifts, refreshShifts }) => {
  useEffect(() => {}, [shifts]);

  function getMonth(date) {
    refreshShifts(date);
  }

  const formatTime =(date)=> {
    const formatter = new Intl.DateTimeFormat('en-GB', { hour: '2-digit', minute: '2-digit' });
    return formatter.format(new Date(date));
}

  function render(date) {
    if (shifts == null) {
      return "";
    }
    const toRender = shifts.Summary.filter((x) => {
      return (
        new Date(x.start).getDate() === date.getDate() &&
        new Date(x.start).getMonth() === date.getMonth()
      );
    });

    return (
      
      <>
        {toRender.map((item, index) => (
          
          <li key={index}>
            {item.holiday === true? <Badge/>:<></>}<b style={{fontSize:10}}> {item.hospital}</b> <p style={{fontSize:10}}>{formatTime(item.start)}</p>
          </li>
        ))}
      </>
    );
  }

  return <Calendar bordered renderCell={render} onMonthChange={getMonth} />;
};

export default Cal;
