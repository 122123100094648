import { useEffect, useState } from "react";
import "bootstrap/dist/css/bootstrap.min.css";

import Cal from "./Cal";
import CustomNavbar from "./Navbar";
import Prompt from "./Prompt";
import CustomSidebar from "./Sidebar";
import "./App.css";

import { AuthProvider } from "oidc-react";

import oidcConfig from "./KC";

function App() {
  const [shifts, setShifts] = useState(null);
  const [loading, setLoading] = useState(true);
  const [month, setMonth] = useState("Loading...");

  const API_URL = "https://api.shifts.dvorster.com/entries/";
  // const API_URL = "http://localhost:30001/entries/"

  console.log("Load!!");

  useEffect(() => {}, [loading]);

  const fetchData = async (date = new Date()) => {
    setLoading(true);
    setMonth(date.toLocaleString("en-us", { month: "short", year: "numeric" }));
    const year = date.getFullYear();
    const month = date.getMonth() + 1;
    await fetch(API_URL + year + "/" + month)
      .then((response) => response.json())
      .then((json) => setShifts(json))
      .catch((error) => console.error(error));
    setLoading(false);
  };

  return (
    <AuthProvider {...oidcConfig}>
      <div className="App">
        {/* <Navbar onDetailsClick={detailsClick} /> */}
        <CustomNavbar appearance="inverse" />
        <Prompt fetchData={fetchData}></Prompt>
        <div class="container-lg">
          <div class="row">
            <div class="col-sm-8">
              <Cal shifts={shifts} refreshShifts={fetchData} />
            </div>
            <CustomSidebar data={shifts} month={month} loading={loading}>
              Sidebar
            </CustomSidebar>
          </div>
        </div>
      </div>
    </AuthProvider>
  );
}

export default App;
