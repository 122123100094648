// import Keycloak from 'keycloak-js';

// const keycloak = new Keycloak({
//     url: 'http://192.168.88.92:8001',
//     realm: 'master',
//     clientId: 'react-fe'
// });

// export default keycloak;

// // try {
// //     const authenticated = await keycloak.init();
// //     console.log(`User is ${authenticated ? 'authenticated' : 'not authenticated'}`);
// // } catch (error) {
// //     console.error('Failed to initialize adapter:', error);
// // }


const oidcConfig = {
    onSignIn: () => {
      // Redirect?
    },
    authority: 'https://keycloak.dvorster.com/realms/homelab',
    clientId: 'react-fe',
    redirectUri: 'https://shifts.dvorster.com/',
  }

export default oidcConfig;