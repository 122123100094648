import React, { useEffect } from "react";

const ShiftItem =(shiftData)=> {
    useEffect(() => {
    },[shiftData])

    const formatFullDate =(date)=> {
        const formatter = new Intl.DateTimeFormat('en-GB', { day: '2-digit', month: 'short', hour: '2-digit', minute: '2-digit' });
        return formatter.format(new Date(date));
    }

    const formatTime =(date)=> {
        const formatter = new Intl.DateTimeFormat('en-GB', { hour: '2-digit', minute: '2-digit' });
        return formatter.format(new Date(date));
    }

    const formatCol = (hospital)=> {
        switch (hospital) {
            case "ERH":
                return ("card-header bg-primary text-light")
            case "Milnerton":
                return ("card-header text-white bg-danger")
            case "KBH":
                return ("card-header text-dark bg-warning")
            case "NCK":
                return ("card-header text-white bg-secondary")
            default:
                return ("card-header text-dark bg-light")
        }
    }

    const formatBorder = (holiday)=> {
        return holiday?"card mb-3 border-1 border-danger":"card mb-3"

    }

    return (
        <>
        <div class={formatBorder(shiftData.shiftData.holiday)}>
        <div class={formatCol(shiftData.shiftData.hospital)}>
        <b>{shiftData.shiftData.hospital}</b>

        </div>
        <div class="card-body">
            <p><b>Shift: </b>{formatFullDate(shiftData.shiftData.start)} - {formatTime(shiftData.shiftData.end)}</p>
            <p><b>Hours: </b>{shiftData.shiftData.duration}</p>
            {(shiftData.shiftData.total === 0)? (
                <p><b>-- No rates --</b></p>
            ):(
                <p><b>Earned: </b>R{shiftData.shiftData.total}.00</p>
            )}
        </div>
        </div>
        </>
    )

}

export default ShiftItem;